<template>
  <div class="layout layout--checkout">
    <slot />
  </div>
</template>

<script>
import Layout from '@/mixins/layout';

export default {
  name: 'Checkout',
  mixins: [Layout],
};
</script>

<style lang="scss">
.layout {
  &--checkout {
    height: 100vh;
  }
}
</style>
